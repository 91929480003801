<template>
  <div class="article">
    <div class="content">
      <!--面包屑-->
      <div class="el-breadcrumb">
        <span class="el-breadcrumb__item">
          <span class="el-breadcrumb__inner">
            当前位置：
          </span>
        </span>
        <span class="el-breadcrumb__item">
          <span class="el-breadcrumb__inner">
            <router-link to="/" class="el-breadcrumb__inner__link"
              :class="{ 'navigation__link_active': $route.name === 'home' }">首页</router-link>
          </span>
          <i class="el-icon-arrow-right"></i>
        </span>
        <span class="el-breadcrumb__item">
          <span class="el-breadcrumb__inner">
            <router-link :to="`/conference/news?id=${conferencesInfo.id}`" class="el-breadcrumb__inner__link"
              :class="{ 'el-breadcrumb__inner__link_active': $route.name === 'conferenceNews' }">大会动态</router-link>
          </span>
          <i class="el-icon-arrow-right"></i>
        </span>
        <span class="el-breadcrumb__item">
          <span class="el-breadcrumb__inner">
            {{ newsTypeStr }}
          </span>
        </span>
      </div>
      <!--面包屑 end-->
      <!--新闻内容-->
      <div class="content_box">
        <el-skeleton animated v-if="loading">
          <template slot="template">
            <el-skeleton-item variant="image" style="width: 240px; height: 240px;" />
            <div style="padding: 14px 0 0 0;">
              <el-skeleton-item variant="p" style="width: 50%; margin-bottom: 10px;" />
              <el-skeleton-item variant="text" style="margin-right: 16px; margin-bottom: 10px;" />
              <el-skeleton-item variant="text" style="width: 30%; margin-bottom: 10px;" />
            </div>
          </template>
        </el-skeleton>

        <template v-else>
          <p class="content_box_bigtit" v-text="newsInfo.newsTitle"></p>
          <p class="content_box_tit">
            信息来源：{{ newsInfo.newsSource }}
            <span class="content_box_tit_time">发布时间：{{ newsInfo.newsTimeStr }}</span>
          </p>
          <div class="content_box_richContent" v-if="newsInfo.newsImgUrl">
            <img :src="newsInfo.newsImgUrl" />
          </div>
          <div class="content_box_richContent" v-html="newsInfo.newsContent" :key="newsInfo.id"></div>
          <p class="content_box_right" v-if="newsInfo.dutyPerson">（责任编辑：{{ newsInfo.dutyPerson }}）</p>
        </template>
      </div>
      <!--新闻内容 end-->
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'ArticleView',
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('common', {
      'conferencesInfo': state => state.conferencesInfo
    }),
    ...mapState('conferences', {
      'newsInfo': state => state.newsInfo
    }),
    newsTypeStr () {
      return {
        1: '新闻动态',
        2: '通知公告'
      }[this.newsInfo.newsType] || '文章详情'
    }
  },
  async created () {
    console.log('url参数', this.$route.params)

    try {
      this.loading = true

      await this.fetchNewsInfo({ id: this.conferencesInfo.id, newsId: this.$route.params.id })
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }

  },
  methods: {
    ...mapActions('conferences', [
      'fetchNewsInfo'
    ])
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables';

img {
  width: 100%;
  height: 100%;
}

.article {
  .content {
    width: $--cms-page-content-width1200;
    margin: 30px auto 0;
    padding-bottom: 100px;

    &_box {
      width: $--cms-page-content-width1140;
      padding: 60px;
      background: #FFFFFF;
      box-shadow: 0px 0 4px 0px #B8B8B8;
      border-radius: 5px;

      &_richContent {
        /* font-size: 16px;
				color: $--cms-color-text-base;
				line-height: $--cms-line-height-middle;
				margin: 20px 0;
				text-indent: $--cms-text-indent;

				::v-deep p {
					font-size: 16px;
					color: $--cms-color-text-base;
					line-height: $--cms-line-height-middle;
					margin: 20px 0;
					text-indent: $--cms-text-indent;
					padding-bottom: 1em;
				}

				::v-deep img {
					display: block;
					margin: 20px auto;
					max-width: 100%;
					height: auto;
				} */

        ::v-deep img {
          max-width: 100%;
          height: auto;
        }
      }

      &_bigtit {
        font-size: 18px;
        color: $--cms-color-text-base;
        position: relative;
        text-align: center;
        width: 100%;
        padding-bottom: 26px;
        font-weight: bold;

        &:before {
          content: '';
          border-bottom: 1px solid #eee;
          width: 100%;
          height: 1px;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      &_tit {
        font-size: 14px;
        color: #999;
        margin-top: 14px;
        padding: 0 0 40px 0;
        text-align: center;
        position: relative;

        &_time {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 14px;
          color: #999;
        }
      }

      &_right {
        text-align: right;
        margin: 70px 0 0 0;
        font-size: 14px;
        color: $--cms-color-text-base;
      }
    }

    .el-breadcrumb {
      font-size: 14px;
      line-height: 1;
      width: 100%;
      height: 50px;
      margin-left: 60px;
      cursor: pointer;

      &:before {
        display: table;
        content: "";
      }

      .el-breadcrumb__item {
        float: left;

        .el-breadcrumb__inner {
          color: #606266;
          vertical-align: text-top;
          text-decoration: none;

          &__link {
            color: #606266;
            text-decoration: none;

            &:hover {
              color: $--cms-link-hover-color;
            }

            &_active {
              background-color: #027BC6;
              color: #FFF;
              border-bottom-color: $--cms-primary-color;

              &:hover {
                color: #FFF;
                opacity: .8;
              }
            }
          }

          .el-breadcrumb__separator {
            margin: 0 9px;
            font-weight: 700;
            color: #c0c4cc;
          }
        }

        .el-icon-arrow-right {
          width: 14px;
          height: 16px;
          display: inline-block;
          vertical-align: text-top;
          margin: 0 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .article {
    .content {
      width: auto;
      padding: 0 10px 50px;

      .el-breadcrumb {
        margin-left: 0;
      }
    }
    .content_box {
      width: auto;
      padding: 10px;
    }

    .content_box_bigtit {
      line-height: 1.5;
    }

    .content_box_tit {
      text-align: left;
      line-height: 1.5;
    }
    .content_box_tit_time {
      position: relative;
      left: 0;
      display: block;
    }
  }
}
</style>