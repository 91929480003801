<template>
<div class="page">
  <template v-if="isDuration">
    <IndexHeade />
    <IndexTable :loading="tableLoading" />
  </template>
  <template v-else>
    <submission-unsubmitted v-if="isUnsubmitted" />
    <submission-end v-if="isSubmittedEnd" />
  </template>
</div>
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex'
import IndexHeade from './components/IndexHeade/index.vue'
import IndexTable from './components/IndexTable/index.vue'
import SubmissionEnd from './components/SubmissionEnd/index.vue'
import SubmissionUnsubmitted from './components/SubmissionUnsubmitted/index.vue'

export default {
  name: 'OnlineSubmissionView',
  components: {
    IndexHeade,
    IndexTable,
    SubmissionEnd,
    SubmissionUnsubmitted
  },
  data() {
    return {
      isShow: false,
      tableLoading: false
    }
  },
  computed: {
    ...mapState('common', {
      'conferencesInfo': state => state.conferencesInfo
    }),
    ...mapState('system', {
      'thesisDateStatus': state => state.thesisDateStatus,
      'thesisList': state => state.thesisList
    }),
    // 投稿期间
    isDuration() {
      return this.thesisDateStatus === 1 || this.thesisList.length
    },
    // 未开始
    isUnsubmitted() {
      return this.thesisDateStatus === 0
    },
    // 投稿结束
    isSubmittedEnd() {
      return this.thesisDateStatus === 2 && !this.thesisList.length
    }
  },
  async created() {
    try {
      this.tableLoading = true

      await this.fetchThesisList({
        conferencesId: this.conferencesInfo.id
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.tableLoading = false
    }
  },
  methods: {
    ...mapActions('system', [
      'fetchThesisList'
    ])
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.page {
  background: #f0f0f0;
  height: 100%;
}
</style>
