<template>
  <div class="layout">
    <PageHeader layout="default">
      <template slot="menu">
        <ul class="navigation">
            <li class="navigation__item" v-for="(item, index) in menus" :key="item.name">
              <router-link :to="item.url || item.path" class="navigation__link"
                :class="{ 'navigation__link_active': $route.name === item.name }">{{ item.title }}</router-link>
            </li>
          </ul>
      </template>
    </PageHeader>

    <div class="page-content">
      <router-view />
    </div>

    <PageFooter />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import PageHeader from '@/components/PageHeader'
import PageFooter from '@/components/PageFooter'

export default {
  components: {
    PageHeader,
    PageFooter
  },
  computed: {
    ...mapState('common', {
      'conferencesInfo': state => state.conferencesInfo
    }),
    ...mapState('conferences', {
      'channels': state => state.channels
    }),
    menus() {
      return this.channels.filter(menu => !menu.hidden)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';


/* ::v-deep {
  .page-header-wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
} */

.page-content {
  // margin-top: 250px;
  min-height: calc(100vh - 471px);
}

@media screen and (max-width: 767px) {
  .page-content {
    padding-top: 15vw;
  }
}
</style>