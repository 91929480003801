import {
  getSystemThesisDateStatus,
  getSystemThesisThesisList,
  postSystemThesisUpsertAbs,
  getSystemThesisThesisInfo,
  getSystemEditorialThesisInfo,
  getSystemAttendInfo,
  postSystemAttendSuppInfo,
  getSystemDictDataType
} from '@/services/system'

// initial state
// shape: [{ id, quantity }]
const state = () => ({
  // 投稿日期状态
  thesisDateStatus: null,
  // 投稿列表
  thesisList: [],
  // 摘要信息
  thesisInfo: {
    authors: []
  },
  // 参会信息
  attendInfo: {},
  // 字典键表
  dictKeys: ['thesis_status', 'attend_reg_status'],
  // 字典
  dicts: {}
})

// getters
const getters = {
}

// actions
const actions = {
  // 获取投稿日期状态
  async fetchThesisDateStatus({ commit, state }, payload) {
    try {
      const data = await getSystemThesisDateStatus(payload)

      commit('setThesisDateStatus', data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  // 获取投稿列表
  async fetchThesisList({ commit, state }, payload) {
    try {
      const data = await getSystemThesisThesisList(payload)

      commit('setThesisList', data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  // 上传或修改摘要
  async postSystemThesisUpsertAbs({ commit, state }, payload) {
    try {
      await postSystemThesisUpsertAbs(payload)

      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    }
  },
  // 论文详情
  async getSystemThesisThesisInfo({ commit, state }, payload) {
    try {
      const data = await getSystemThesisThesisInfo(payload)

      commit('setThesisInfo', data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  // 编辑部论文详情
  async getSystemEditorialThesisInfo({ commit, state }, payload) {
    try {
      const data = await getSystemEditorialThesisInfo(payload)

      commit('setThesisInfo', data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  // 重置摘要信息
  resetThesisInfo({ commit, state }, payload) {
    commit('setThesisInfo', {
      authors: []
    })
  },
  // 获取参会信息
  async getSystemAttendInfo({ commit, state }, payload) {
    try {
      const data = await getSystemAttendInfo(payload)

      commit('setAttendInfo', data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  // 补充信息
  async postSystemAttendSuppInfo({ commit, state }, payload) {
    try {
      const data = await postSystemAttendSuppInfo(payload)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  // 获取所有字典
  async fetchDictTypeByKeys({ dispatch, state }, payload) {
    try {
      const keys = payload || state.dictKeys

      const queue = keys.map(key => dispatch('getSystemDictDataType', key))

      const dicts = await Promise.all(queue)

      return Promise.resolve(dicts)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  // 获取字典
  async getSystemDictDataType({ commit, state }, name) {
    try {
      const data = await getSystemDictDataType(name)

      commit('setDictDataType', name, data)

      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

// mutations
const mutations = {
  // 设置投稿日期状态
  setThesisDateStatus(state, payload) {
    state.thesisDateStatus = payload
  },
  // 设置投稿列表
  setThesisList(state, payload) {
    state.thesisList = payload
  },
  // 设置摘要信息
  setThesisInfo(state, payload) {
    state.thesisInfo = payload
  },
  // 设置参会信息
  setAttendInfo(state, payload) {
    state.attendInfo = payload
  },
  // 设置字典
  setDictDataType(state, name, payload) {
    state.dicts[name] = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}