<template></template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('user', [
      'roleNames'
    ])
  },
  created() {
    if (this.roleNames.includes('editorial')) {
      this.$router.replace('/editorial/userinfo')
    } else {
      this.$router.replace('/user/info')
    }
  }
}
</script>