<template>
	<div class="article__body">
		<div class="header__body">
			<el-row type="flex" justify="end" class="header__body_mr30">
				<Space>
					<el-button type="primary" @click="handleUpdate">修改</el-button>
				</Space>
			</el-row>
		</div>
		<dl class="article__body-dl">
			<dd class="article__body-dl-dd">
				<span class="tit">用户名：</span>
				<span class="text">{{ userInfo.userName }}</span>
			</dd>
			<dd class="article__body-dl-dd">
				<span class="tit">真实姓名：</span>
				<span class="text">{{ userInfo.nickName }}</span>
			</dd>
			<dd class="article__body-dl-dd">
				<span class="tit">身份证号码：</span>
				<span class="text">{{ userInfo.idNumber }}</span>
			</dd>
			<dd class="article__body-dl-dd">
				<span class="tit">专业方向：</span>
				<span class="text">{{ userInfo.specialty }}</span>
			</dd>
			<dd class="article__body-dl-dd">
				<span class="tit">单位：</span>
				<span class="text">{{ userInfo.workUnit }}</span>
			</dd>
			<dd class="article__body-dl-dd">
				<span class="tit">职称：</span>
				<span class="text">{{ userInfo.jobTitle }}</span>
			</dd>
			<dd class="article__body-dl-dd">
				<span class="tit">通讯地址：</span>
				<div class="text">
					<!--<el-select class="select" v-model="province" placeholder="" :disabled="true">
					    <el-option
					      v-for="item in provinceOptions"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value">
					    </el-option>
					  </el-select>
					  <el-select class="select" v-model="userInfo.province" placeholder="" :disabled="true">
					      <el-option
					        v-for="item in cityOptions"
					        :key="item.value"
					        :label="item.label"
					        :value="item.value">
					      </el-option>
					    </el-select>
						<el-select class="select margin0" v-model="userInfo.city" placeholder="" :disabled="true">
						<el-option
						  v-for="item in countyOptions"
						  :key="item.value"
						  :label="item.label"
						  :value="item.value">
						</el-option>
					  </el-select> -->
					<div class="input">{{ userInfo.address }}</div>
				</div>
			</dd>
			<dd class="article__body-dl-dd">
				<span class="tit">邮编：</span>
				<span class="text">{{ userInfo.postCode }}</span>
			</dd>
			<dd class="article__body-dl-dd">
				<span class="tit">手机号码：</span>
				<span class="text">{{ userInfo.phonenumber }}</span>
			</dd>
			<dd class="article__body-dl-dd">
				<span class="tit">邮箱：</span>
				<span class="text">{{ userInfo.email }}</span>
			</dd>
		</dl>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
	components: {
	},
	data() {
		return {
			province: '河北省',
			city: '廊坊市',
			county: '固安县',
			fullAddress: '孔雀城大卫城51号楼2302',
			provinceOptions: [{
				value: '选项1',
				label: '黄金糕'
			}, {
				value: '选项2',
				label: '双皮奶'
			}, {
				value: '选项3',
				label: '蚵仔煎'
			}, {
				value: '选项4',
				label: '龙须面'
			}, {
				value: '选项5',
				label: '北京烤鸭'
			}],
			cityOptions: [],
			countyOptions: []
		}
	},
	computed: {
		...mapState('user', {
			'userInfo': state => state.userInfo
		})
	},
	async created() {
		try {
			await this.fetchUserInfo()
		} catch (e) {
			//TODO handle the exception
		}
	},
	mounted() {

	},
	methods: {
		...mapActions('user', [
			'fetchUserInfo'
		]),
		handleUpdate() {
			this.$router.push({
				path: '/user/info/update'
			})
		}
	},
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.margin0 {
	margin: 0 !important;
}

.header__body {
	padding: 20px 0;
	background: #f0f0f0;

	&_mr30 {
		margin-right: 30px;
	}

	.el-button {
		width: 110px !important;
	}
}

.article__body {
	background: #fff;
	padding: 0 0 0 0;

	&-bigTit {
		font-size: 20px;
		color: #003F88;
		line-height: 28px;
		font-weight: 500;
	}

	&-dl {
		display: inline-block;
		box-sizing: border-box;
		width: 100%;
		padding: 0 0 100px 20px;

		&-dd {
			width: 100%;
			margin: 30px 0 0 0;
			display: flex;
			line-height: 30px;

			.tit {
				font-size: 18px;
				color: #333;
				font-weight: bold;
				width: 125px;
			}

			.text {
				width: 60%;
				font-weight: 400;
				font-size: 18px;
				color: #333333;
				text-align: left;
			}

			.select {
				margin: 0 40px 0 0;
				width: 30%;

			}

			.input {
				margin: 0;
				width: 100%;
				display: block;
			}
		}
	}
}

::v-deep {
	.el-input.is-disabled .el-input__inner {
		color: #333;
	}
}

@media screen and (max-width: 767px) {
	.article__body-dl {
		box-sizing: border-box;
	}
}
</style>
