<template>
    <div class="space" :class="[`${direction} ${align}`, { wrap }]"
        :style="{ 'width': spaceWidth, gap, 'margin-bottom': `-${Array.isArray(size) && wrap ? size[1] : 0}px` }">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'Space',
    props: {
        width: {
            type: String,
            default: 'auto'
        },
        align: {
            type: String,
            default: 'start'
        },
        direction: {
            type: String,
            default: 'horizontal'
        },
        size: {
            type: String,
            default: 'small'
        },
        wrap: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        spaceWidth() {
            if (typeof this.width === 'number') {
                return this.width + 'px'
            }

            return this.width
        },
        gap() {
            if (typeof this.size === 'number') {
                return this.size + 'px'
            }

            if (Array.isArray(this.size)) {
                return this.size[1] + 'px ' + this.size[0] + 'px '
            }

            if (['small', 'middle', 'large'].includes(this.size)) {
                const gapMap = {
                    small: '8px',
                    middle: '16px',
                    large: '24px'
                }

                return gapMap[this.size]
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.space {
    display: inline-flex;
    font-size: 14px;
    color: rgba(0, 0, 0, .88);
    transition: all .3s;
}

.horizontal {
    flex-direction: row;
}

.vertical {
    flex-direction: column;
}

.stretch {
    align-items: stretch;
}

.start {
    align-items: flex-start;
}

.end {
    align-items: flex-end;
}

.center {
    align-items: center;
}

.baseline {
    align-items: baseline;
}

.wrap {
    flex-wrap: wrap;
}
</style>