<template>
  <div class="page-header-wrap">
    <div class="page-header">
      <h1 class="logo"><a href="/"><img :src="conferencesInfo && conferencesInfo.logoUrl"
            :alt="conferencesInfo.title" /></a></h1>
      <ul class="actions">
        <li class="action__item">
          <router-link to="/" class="backhome">返回首页</router-link>
        </li>
        <li class="action__item">

          <el-popover placement="bottom-end" width="380" v-model="logoutVisible" :visible-arrow="false">
            <div class="admin__navmenu-popover">
              <div class="admin__navmenu-popover-head">
                <img class="admin__navmenu-popover-avatar" src="@/assets/images/user/avatar-white.png" />
                <div class="admin__navmenu-popover-info">
                  <div>{{ userInfo.nickName }}</div>
                  <div>用户名：{{ userInfo.userName }}</div>
                </div>
              </div>
              <div class="admin__navmenu-popover-body">
                <ul class="admin__navmenu-popover-menu">
                  <li class="admin__navmenu-popover-item"><a href="javascript:;"
                      @click="changePasswordVisible = true"><i class="icon icon-edit"></i>修改密码</a></li>
                </ul>
              </div>
              <div class="admin__navmenu-popover-foot">
                <el-button type="primary" @click="() => handleLogout()">安全退出</el-button>
              </div>
            </div>

            <div class="admin__navmenu" slot="reference">
              <div class="admin__navmenu-head">
                <span class="admin__navmenu-name">{{ userInfo.userName }}</span>
                <img class="admin__navmenu-avatar" src="@/assets/images/user/avatar.png" />
              </div>
            </div>
          </el-popover>
        </li>
      </ul>
    </div>

    <ChangePassword :visible.sync="changePasswordVisible" />
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions
} from 'vuex'
import ChangePassword from '@/components/ChangePassword'

export default {
  components: {
    ChangePassword
  },
  data() {
    return {
      logoutVisible: false,
      changePasswordVisible: false
    }
  },
  computed: {
    ...mapState('common', {
      'conferencesInfo': state => state.conferencesInfo
    }),
    ...mapState('user', {
      'userInfo': state => state.userInfo
    })
  },
  created() {
    // this.logoutVisible = false
  },
  methods: {
    ...mapActions('user', [
      'doLogout'
    ]),
    handleLogout() {
      this.logoutVisible = false

      this.doLogout()

      this.$router.push({
        path: '/login'
      })
    }
  },
  watch: {
    '$route'() {
      this.logoutVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.logo {
  position: relative;
}

.page-header {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #EEEEEE;
  margin: 0 -20px;
  padding: 0 20px;

  &-wrap {
    height: 100%;
  }

  .actions {
    display: flex;
    align-items: center;
  }

  .action {
    &__item {
      font-size: $--cms-font-size;
      color: #666;
      line-height: $--cms-line-height;
      font-weight: bold;

      a {
        color: #666;
        text-decoration: none;

        &:hover {
          color: $--cms-link-hover-color;
        }
      }

      .backhome {
        display: block;
        width: 112px;
        height: 28px;
        background: url('@/assets/images/user/btn-back.png') no-repeat 0 0;
        line-height: 100px;
        overflow: hidden;

        &:hover {
          opacity: .8;
        }
      }
    }
  }
}

.admin__navmenu {
  margin-left: 50px;

  &-head {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &-name {
    font-weight: 600;
    font-size: 24px;
    color: $--cms-primary-color;
    line-height: 34px;
  }

  &-avatar {
    width: 36px;
    height: 34px;
    object-fit: contain;
    margin-left: 18px;
  }

  &-popover {
    margin: -12px -12px;

    &-head {
      padding: 35px 30px;
      background-color: #2D47AE;
      display: flex;
    }

    &-body {
      padding: 10px 10px;
      min-height: 100px;
    }

    &-foot {
      padding: 0 0 20px;
      display: flex;
      justify-content: center;
    }

    &-info {
      color: #FFFFFF;
      font-size: $--cms-font-size-md;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-avatar {
      margin-right: 20px;
    }

    &-item {
      font-weight: 500;
      font-size: $--cms-font-size-md;
      color: rgba(0, 0, 0, 0.88);
      line-height: 22px;
      padding: 14px 20px;
      display: flex;
      align-items: center;

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: rgba(0, 0, 0, 0.88);

        &:hover {
          color: $--cms-link-hover-color;
        }
      }

      .icon {
        margin-right: 16px;
      }
    }
  }
}

.icon {
  &-edit {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url('@/assets/images/common/icon-edit.png') no-repeat 0 0;
    background-size: contain;
  }
}

@media screen and (max-width: 767px) {
  .page-header-wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: #FFF;
    height: 15vw;
  }
}
</style>
