import { conferencesInfo } from '@/services/common'
import dayjs from 'dayjs'

// initial state
// shape: [{ id, quantity }]
const state = () => ({
  // 大会基本信息
  conferencesInfo: {}
})

// getters
const getters = {
}

// actions
const actions = {
  async fetchConferencesInfo({ commit, state }) {
    try {
      const data = await conferencesInfo()

      if (data.dates) {
        data.dates = data.dates.map(item => {
          item.nodeDateStr = dayjs(item.nodeDate).format('YYYY年M月D日')

          return item
        })
      }

      commit('setConferencesInfo', data)

      return Promise.resolve(data)

    } catch (e) {
      return Promise.reject(e)
    }

  }
}

// mutations
const mutations = {
  setAccessToken(state, payload) {
    state.accessToken = payload
  },
  setConferencesInfo(state, payload) {
    state.conferencesInfo = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}