var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-header-wrap"},[_c('div',{staticClass:"page-header"},[_c('div',{staticClass:"page-header__content"},[_c('h1',{staticClass:"logo"},[_c('a',{attrs:{"href":"/"}},[_c('img',{attrs:{"src":_vm.conferencesInfo && _vm.conferencesInfo.logoUrl,"alt":_vm.conferencesInfo.title}})])])]),(_vm.layout === 'default')?[_c('div',{staticClass:"search"},[(_vm.searchVisible)?_c('div',{staticClass:"search-mask",attrs:{"show-in-sm":""},on:{"click":function($event){_vm.searchVisible = false}}}):_vm._e(),_c('i',{staticClass:"el-icon-search",on:{"click":function($event){_vm.searchVisible = !_vm.searchVisible}}}),_c('div',{staticClass:"search-inner",class:{ 'search-inner-show': _vm.searchVisible }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keywords),expression:"keywords"}],staticClass:"input",attrs:{"type":"text","placeholder":"请输入关键词进行搜索"},domProps:{"value":(_vm.keywords)},on:{"input":function($event){if($event.target.composing)return;_vm.keywords=$event.target.value}}}),_c('button',{staticClass:"button",on:{"click":_vm.handleSearch}},[_vm._v("搜索")])])])]:_vm._e(),(_vm.layout === 'admin')?[_c('router-link',{staticClass:"gohome",attrs:{"to":"/"}},[_c('i',{staticClass:"el-icon-s-home"})])]:_vm._e(),_c('div',{staticClass:"page-header__menu",class:{ 'page-header__menu_show': _vm.menuVisible }},[_c('i',{staticClass:"page-header__menu-icon",on:{"click":() => {
        _vm.menuVisible = !_vm.menuVisible;
        _vm.searchVisible = false;
      }}}),_c('div',{staticClass:"page-header__menu-mask",on:{"click":function($event){_vm.menuVisible = false}}}),_c('div',{staticClass:"page-header__menu-layer"},[_c('i',{staticClass:"page-header__menu-close el-icon-close",on:{"click":function($event){_vm.menuVisible = false}}}),_c('div',{staticClass:"page-header__menu-avatar"}),_c('ul',{staticClass:"actions"},[(_vm.userInfo.userName)?[_c('li',{staticClass:"action__item"},[_vm._v(_vm._s(_vm.userInfo.userName))]),_c('li',{staticClass:"action__item"},[_c('router-link',{attrs:{"to":"/user"}},[_vm._v("用户中心")])],1),_c('li',{staticClass:"action__item action__logout",attrs:{"show-in-lg":""}},[_c('el-popover',{attrs:{"placement":"bottom","width":"160"},model:{value:(_vm.logoutVisible),callback:function ($$v) {_vm.logoutVisible=$$v},expression:"logoutVisible"}},[_c('p',[_vm._v("确定登出？")]),_c('div',{staticStyle:{"text-align":"right","margin":"0"}},[_c('el-button',{attrs:{"size":"mini","type":"text"},on:{"click":function($event){_vm.logoutVisible = false}}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":() => _vm.doLogout()}},[_vm._v("确定")])],1),_c('a',{staticClass:"action__link",attrs:{"slot":"reference","href":"javascript:;"},slot:"reference"},[_vm._v("退出登录")])])],1),_c('li',{staticClass:"action__item m-action__logout",attrs:{"show-in-sm":""}},[_c('a',{staticClass:"action__link",attrs:{"href":"javascript:;"},on:{"click":() => {
                _vm.$dialog.confirm({
                  title: '提示',
                  message: '确定要退出登录？',
                })
                  .then(() => {
                    _vm.doLogout();

                    _vm.$message.success('已退出登录');

                    _vm.menuVisible = false;

                    _vm.$router.push({
                      path: '/'
                    }, () => {})
                  })
                  .catch(() => {
                    // on cancel
                  })

              }}},[_vm._v("退出登录")])])]:void 0],2),_c('div',{on:{"click":function($event){_vm.menuVisible = false}}},[_vm._t("menu")],2)])])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }