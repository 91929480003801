<template>
    <div class="header__body">
		<img  class="img" src="https://s21.ax1x.com/2024/05/29/pk3lk80.png"/>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    components: {
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapState('common', {
            'conferencesInfo': state => state.conferencesInfo
        }),
    },
    mounted() {
    },
	methods:{
		goBack() {
			this.$router.go(-1);
		}
	}
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.header__body {
    position:relative;
	.img{
		width: 140px;
		height: 198px;
		position: absolute;
		left: 50%;
		top: 172px;
		transform: translateX(-50%);
		img{
			width: 100%;
			height: 100%;
		}
	}
}

</style>
