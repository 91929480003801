<template>
	<div class="header__body">
		<el-row type="flex" justify="end" class="header__body_mr30">
			<Space>
				<el-button type="primary" plain @click="goBack">返回</el-button>
				<router-link :to="`/user/submission/update/${thesisInfo.thesisId}`"><el-button
						:type="thesisInfo.operationStat !== 2 ? 'info' : 'primary'"
						:disabled="thesisInfo.operationStat !== 2">上传全文</el-button></router-link>
			</Space>
		</el-row>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	components: {
	},
	data() {
		return {
		}
	},
	computed: {
		...mapState('system', {
			'thesisInfo': state => state.thesisInfo,
			'thesisDateStatus': state => state.thesisDateStatus,
		})
	},
	mounted() {
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		handleUploadFullPaper() {

		}
	}
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.header__body {
	padding: 20px 0;

	&_mr30 {
		margin-right: 30px;
	}

	.el-button {
		width: 110px !important;
	}
}
</style>
