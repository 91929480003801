<template>
<div class="article__body">
  <div class="header__body">
    <el-row type="flex" justify="end" class="header__body_mr30">
      <Space>
        <el-button type="primary" plain @click="goBack">返回</el-button>
        <el-button type="primary" @click="handleSubmit">保存</el-button>
      </Space>
    </el-row>
  </div>

  <div class="article__body-dl">
    <el-form ref="form" :model="userInfo" :rules="formRules" label-width="auto">
      <div class="article__body-dl-dd">
        <span class="tit">用户名：</span>
        <span class="text">{{userInfo.userName}}</span>
      </div>
      <el-form-item label="真实姓名：">
        <el-input v-model="userInfo.nickName"></el-input>
      </el-form-item>
      <el-form-item label="身份证号码：">
        <el-input v-model="userInfo.idNumber"></el-input>
      </el-form-item>
      <el-form-item label="专业方向：">
        <el-input v-model="userInfo.specialty"></el-input>
      </el-form-item>
      <el-form-item label="单位：">
        <el-input v-model="userInfo.workUnit"></el-input>
      </el-form-item>
      <el-form-item label="职称：">
        <el-input v-model="userInfo.jobTitle"></el-input>
      </el-form-item>
      <el-form-item label="通讯地址：">
        <el-input v-model="userInfo.address"></el-input>
      </el-form-item>
      <el-form-item label="邮编：">
        <el-input v-model="userInfo.postCode"></el-input>
      </el-form-item>
      <el-form-item label="手机号码：">
        <el-input v-model="userInfo.phonenumber"></el-input>
      </el-form-item>
      <el-form-item label="邮箱：">
        <el-input v-model="userInfo.email" class="form-item__imgcode"></el-input>
        <span class="form-item__imgcode-img" @click="handlSendEcode"><i v-if="sendLoading" class="el-icon-loading" style="margin-right: 5px;"></i>发送验证码</span>
      </el-form-item>
      <el-form-item label="邮箱验证码：" prop="eCode" v-if="userInfo.email">
        <el-input v-model="userInfo.eCode"></el-input>
      </el-form-item>
    </el-form>
  </div>
</div>
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex'
import {
  Message
} from 'element-ui'

export default {
  components: {},
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        callback('请输入正确的邮箱格式')
      } else {
        callback()
      }
    }
    return {
      cityOptions: [],
      countyOptions: [],
      sendLoading: false,
      formRules: {
        // 邮箱
        email: [{
          required: true,
          message: '请输入邮箱'
        }, {
          validator: validateEmail,
          trigger: 'blur'
        }],
        // 邮箱验证码
        /* eCode: [{
          required: true,
          message: '请输入邮箱验证码'
        }], */
      }
    }
  },
  computed: {
    ...mapState('user', {
      'userInfo': state => state.userInfo
    }),

  },
  async created() {
    try {
      await this.fetchUserInfo()
    } catch (e) {
      //TODO handle the exception
    }
  },
  mounted() {

  },
  methods: {
    ...mapActions('user', [
      'postUpdateDetail',
      'fetchUserInfo',
      'fetchSendEcode',

    ]),
    goBack() {
      this.$router.go(-1);
    },
    handleSubmit(values) {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          try {
            const params = {
              ...this.userInfo
            }
            const data = await this.postUpdateDetail(params)

            Message.success({
              message: '恭喜您修改成功!'
            })
            this.$router.push({
              path: '/user/info'
            })
          } catch (e) {
            console.error('修改失败', e)
          }
        } else {
          return false
        }
      })
    },
    // 发送邮箱证码
    async handlSendEcode() {
      try {
        if (this.sendLoading) {
          return false
        }

        this.sendLoading = true

        await this.fetchSendEcode({
          email: this.userInfo.email
        })
      } catch (e) {
        console.log('发送邮箱验证码失败', e)
      } finally {
        this.sendLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.margin0 {
  margin: 0 !important;
}

.header__body {
  padding: 20px 0;
  background: #f0f0f0;

  &_mr30 {
    margin-right: 30px;
  }

  .el-button {
    width: 110px !important;
  }
}

.article__body {
  background: #fff;
  padding: 0 30px 0 0;

  .form-item__imgcode {
    width: 78%;
    margin-right: 18px;
  }

  .form-item__imgcode-img {
    display: inline-block;
    width: 110px;
    height: 40px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 40px;
    text-align: center;
    font-style: normal;
    background: #003F88;
    color: #fff;
  }

  .mailTips {
    margin-left: 0;
    font-size: 12px;
    color: #6EAE40;
  }

  &-bigTit {
    font-size: 20px;
    color: #003F88;
    line-height: 28px;
    font-weight: 500;
  }

  &-dl {
    display: inline-block;
    width: 100%;
    padding: 0 0 100px 20px;

    &-dd {
      width: 100%;
      margin: 30px 0 0 0;
      display: flex;
      line-height: 30px;

      .tit {
        font-size: 18px;
        color: #333;
        font-weight: bold;
        width: 125px;
      }

      .text {
        width: 60%;
        font-weight: 400;
        font-size: 18px;
        color: #333333;
        text-align: left;
        position: relative;

      }

      .select {
        margin: 0 40px 30px 0;
        width: 20%;

      }

      .input {
        margin: 0;
      }

      .w460 {
        width: 85%;
        float: left;
        margin-right: 17px;
      }

      .codeBtn {
        display: inline-block;
        width: 110px;
        height: 40px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 40px;
        text-align: center;
        font-style: normal;
        background: #003F88;
        color: #fff;
      }
    }
  }
}

::v-deep {
  .el-input.is-disabled .el-input__inner {
    color: #333;
  }

  .el-form-item {
    margin: 30px 0;
    width: 706px;
  }

  .el-form-item__label {
    font-size: 18px;
    color: #333;
    font-weight: bold;
  }

  .el-select {
    margin-right: 15px;
  }

  .el-form-item__label-wrap {
    float: left;
    margin-left: 0 !important;
    text-align: left;
  }
  .el-input__inner{
	  font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .article__body {
    padding: 0;

    .form-item__imgcode {
      width: 100%;
      margin-right: 0;

      &-img {
        width: 100%;
		margin-top: 10px;
      }
    }
  }

  .el-form-item {
    width: auto;
  }

  .article__body-dl {
    display: block;
    padding: 0 20px 20px;
    box-sizing: border-box;
  }
}
</style>
