<template>
  <div class="page-container">
    <div class="page-header">
      <el-button type="primary" @click="() => handleAdd()">添加作者</el-button>
    </div>
    <div class="page-body">
      <el-table :data="thesisInfo.authors" border style="width: 100%; margin-bottom: 20px;">
        <el-table-column type="index" label="作者顺序" width="80">
        </el-table-column>
        <el-table-column prop="authorName" label="姓名" width="180">
          <template slot-scope="{row}">
            {{ row.authorName + (row.isComm ? '（通讯作者）' : '') }}
          </template>
        </el-table-column>
        <el-table-column prop="authorUnit" label="单位">
        </el-table-column>
        <el-table-column prop="authorEmail" label="邮箱">
        </el-table-column>
        <el-table-column prop="authorPhone" label="联系方式">
        </el-table-column>
        <el-table-column prop="option" label="操作" width="280">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain @click="handleMove(scope.$index, -1)"
              :disabled="scope.$index === 0">上移</el-button>
            <el-button size="mini" type="primary" plain @click="handleMove(scope.$index, 1)"
              :disabled="scope.$index === thesisInfo.authors.length - 1">下移</el-button>
            <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-popconfirm icon="el-icon-info" icon-color="red" @confirm="handleDelete(scope.$index)" title="确定要删除吗？">
              <el-button slot="reference" size="mini" type="danger" style="margin-left: 10px;">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-footer">
      <Space>
        <el-button type="primary" @click="handlePrevStep">上一步</el-button>
        <el-button type="primary" @click="handleNextStep">下一步</el-button>
      </Space>
    </div>

    <el-dialog :title="`${actionName}作者`" :visible.sync="formVisible" width="900px" :destroy-on-close="true">
      <p class="dialog-tips">提示：所填作者信息，需与稿件内作者保持一致，并严格按照格式进行填写。</p>

      <el-form ref="form" :model="form" :rules="rules" label-position="left" v-if="formVisible">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="姓名" :label-width="formLabelWidth" prop="authorName">
              <el-input v-model="form.authorName" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位" :label-width="formLabelWidth" prop="authorUnit">
              <el-input v-model="form.authorUnit" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮箱" :label-width="formLabelWidth" prop="authorEmail">
              <el-input v-model="form.authorEmail" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系方式" :label-width="formLabelWidth" prop="authorPhone">
              <el-input v-model="form.authorPhone" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label-width="formLabelWidth" prop="isComm">
              <el-checkbox v-model="form.isComm" label="通讯作者" :true-label="1" :false-label="0"></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="formVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleFormSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  mapState,
  mapMutations
} from 'vuex'

import lodash from 'lodash'

const defaultFormValues = {
  authorName: '',
  authorUnit: '',
  authorEmail: '',
  authorPhone: '',
  isComm: null
}

export default {
  props: {
    current: Number
  },
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        callback('请输入正确的邮箱格式')
      } else {
        callback()
      }
    }
    return {
      action: '',
      authorIndex: -1,
      formVisible: false,
      formLabelWidth: '80px',
      form: {
        ...defaultFormValues
      },
      rules: {
        authorName: [{
          required: true,
          message: '请输入姓名'
        }],
        authorUnit: [{
          required: true,
          message: '请输入单位'
        }],
        authorEmail: [{
          required: true,
          message: '请输入邮箱'
        }, {
          validator: validateEmail,
          trigger: 'blur'
        }],
        /* authorPhone: [{
          required: true,
          message: '请输入联系方式'
        }] */
      }
    }
  },
  computed: {
    ...mapState('system', {
      thesisInfo: state => state.thesisInfo
    }),
    actionName() {
      return {
        'add': '添加',
        'edit': '编辑'
      }[this.action]
    }
  },
  methods: {
    ...mapMutations('system', {
      setThesisInfo: 'setThesisInfo'
    }),
    handleAdd() {
      this.action = 'add'
      this.formVisible = true

      this.form = {
        ...defaultFormValues
      }

      this.$nextTick(() => {
        this.$refs['form'].resetFields()
        this.$refs['form'].clearValidate()
      })
    },
    handleEdit(index, row) {
      this.action = 'edit'
      this.formVisible = true
      this.authorIndex = index

      this.form = row
    },
    handleMove(index, offset) {
      const thesisInfo = {
        ...this.thesisInfo
      }

      thesisInfo.authors.splice(index + offset, 0, thesisInfo.authors.splice(index, 1)[0])

      this.setThesisInfo({
        ...thesisInfo
      })
    },
    handleDelete(index) {
      const thesisInfo = {
        ...this.thesisInfo
      }

      thesisInfo.authors.splice(index, 1)

      this.setThesisInfo({
        ...thesisInfo
      })
    },
    handleFormSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.formVisible = false

          const thesisInfo = {
            ...this.thesisInfo
          }

          if (this.action === 'add') {
            thesisInfo.authors.push({
              ...this.form
            })
          }

          if (this.action === 'edit') {
            thesisInfo.authors[this.authorIndex] = {
              ...this.form
            }
          }

          this.setThesisInfo({
            ...thesisInfo
          })
        } else {
          return false
        }
      })
    },
    handlePrevStep() {
      this.$emit('onCurrentChange', this.current - 1)
    },
    handleNextStep() {
      this.$emit('onCurrentChange', this.current + 1)
    }
  },
  watch: {
    formVisible(value) {
      if (!value) {
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.page {
  &-header {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 20px;
  }

  &-body {
    min-height: calc(100vh - 320px);
  }

  &-footer {
    display: flex;
    justify-content: center;
  }
}

.dialog {
  &-tips {
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.88);
    line-height: 22px;
    // padding-left: 10px;
    padding-bottom: 40px;
  }
}
</style>
