<template>
	<div class="article__body">
		<div class="article__body-bigTit">论文信息</div>
		<dl class="article__body-dl">
			<dd class="article__body-dl-dd">
				<span class="tit">论文题目：</span>
				<span class="text">{{ thesisInfo.thesisTopic || '--' }}</span>
			</dd>
			<dd class="article__body-dl-dd">
				<span class="tit">关键词：</span>
				<span class="text">{{ thesisInfo.thesisKeys || '--' }}</span>
			</dd>
			<dd class="article__body-dl-dd">
				<span class="tit">摘要：</span>
				<span class="text">{{ thesisInfo.thesisAbs || '--' }}</span>
			</dd>
			<dd class="article__body-dl-dd">
				<span class="tit">所投专题：</span>
				<span class="text">{{ thesisInfo.subject || '--' }}</span>
			</dd>
			<dd class="article__body-dl-dd">
				<span class="tit">论文状态：</span>
				<span class="text">{{ thesisInfo.thesisStatus | filterDictTypeLabel(dict.type.thesis_status, '--')
					}}</span>
			</dd>
		</dl>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { filterDictTypeLabel } from '@/filters'

export default {
	dicts: ['thesis_status'],
	components: {
	},
	filters: {
		filterDictTypeLabel
	},
	data() {
		return {
		}
	},
	computed: {
		...mapState('system', {
			'thesisInfo': state => state.thesisInfo
		})
	},
	mounted() {
	}
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.article__body {
	background: #fff;
	padding: 20px;

	&-bigTit {
		font-size: 20px;
		color: #003F88;
		line-height: 28px;
		font-weight: 500;
	}

	&-dl {
		display: block;

		&-dd {
			width: 100%;
			margin: 30px 0 0 0;
			display: flex;
			line-height: 30px;

			.tit {
				font-size: 14px;
				color: #333;
				font-weight: 500;
				width: 100px;
			}

			.text {
				width: 100%;
				font-weight: 400;
				font-size: 14px;
				color: #333333;
				text-align: left;
			}
		}
	}
}
</style>
