<template>
    <div class="common-banner" v-if="channel.bannerVisible" :style="bannerStyle"></div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    name: 'CommonBanner',
    computed: {
        ...mapState('common', {
			'conferencesInfo': state => state.conferencesInfo
		}),
		...mapState('conferences', {
			'channel': state => state.channel
		}),
        bannerStyle() {
            let styles = {}

            if (this.channel && this.channel.bannerUrl) {
                styles['background-image'] = `url(${this.channel.bannerUrl})`
            }

            if (this.channel && this.channel.bannerStyle) {
                styles = {
                    ...styles,
                    ...JSON.parse(this.channel.bannerStyle)
                }
            }

            return styles
        }
    },
    methods: {
        ...mapActions('conferences', [
			'fetchConferencesChannel'
		]),
    },
    async created() {
		const { meta = {} } = this.$route
    	const { raw = {} } = meta

		try {
			if (raw.id) {
				await this.fetchConferencesChannel({
					id: raw.id
				})
			}
		} catch (e) {
			console.error(e)
		}
	},
}
</script>

<style lang="scss" scoped>
.common-banner {
    height: 450px;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center 0;
}

@media screen and (max-width: 767px) {
  .common-banner {
    height: 30vw;
  }
}
</style>