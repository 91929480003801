<template>
  <el-form ref="form" :model="form" :rules="rules" label-width="80px">
    <el-form-item label="论文题目" prop="thesisTopic">
      <el-input v-model="form.thesisTopic" placeholder="请输入论文题目" clearable></el-input>
    </el-form-item>

    <el-form-item label="关键词" prop="thesisKeys">
      <el-input v-model="form.thesisKeys" placeholder="支持多个关键词，以、隔开" clearable></el-input>
    </el-form-item>

    <el-form-item label="摘要" prop="thesisAbs">
      <el-input type="textarea" :rows="5" v-model="form.thesisAbs" placeholder="论文摘要" clearable></el-input>
    </el-form-item>

    <el-form-item label="所投专题" prop="subId">
      <el-select v-model="form.subId" placeholder="请选择" style="width: 100%;" clearable>
        <el-option :label="item.subject" :value="item.subId" v-for="item in introduction.subjects"
          :key="item.subId"></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="论文摘要" prop="absDocUrl">
      <el-input v-model="form.absDocUrl" placeholder="请点击右侧上传文件" disabled clearable>
        <template slot="append">
          <el-upload :action="`${baseURL}/system/thesis/uploadFile`" :show-file-list="false"
            :before-upload="(file) => handleBeforeUpload(file, 20 * 1024 * 1024)"
            :on-success="(response) => handleUploadSuccess(response, 'absDocUrl')" accept=".doc, .docx, .pdf">
            <el-button type="primary" icon="el-icon-upload2">上传文件</el-button>
          </el-upload>
        </template>
      </el-input>
      <p class="tips">仅允许上传doc、docx 、pdf格式文件，文件大小不超过20M。</p>
    </el-form-item>

    <el-form-item>
      <el-row type="flex" justify="center">
        <el-button type="primary" @click="onSubmit">下一步</el-button>
      </el-row>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { baseURL } from '@/utils/request'

export default {
  props: {
    current: Number
  },
  data() {
    return {
      baseURL,
      form: {
        thesisTopic: '',
        thesisKeys: '',
        thesisAbs: '',
        subId: null,
        absDocUrl: '',
        
      },
      rules: {
        thesisTopic: [{ required: true, message: '请输入论文题目' }],
        // thesisKeys: [{ required: true, message: '请输入关键词' }],
        // thesisAbs: [{ required: true, message: '请输入摘要' }],
        subId: [{ required: true, message: '请选择所投专题' }],
        absDocUrl: [{ required: true, message: '请上传论文摘要' }],
        
      }
    }
  },
  computed: {
    ...mapState('user', {
      'access_token': state => state.token.access_token
    }),
    ...mapState('common', {
      'conferencesInfo': state => state.conferencesInfo
    }),
    ...mapState('system', {
      'thesisInfo': state => state.thesisInfo
    }),
    ...mapState('conferences', {
      'introduction': state => state.introduction
    })
  },
  methods: {
    ...mapActions('conferences', [
      'fetchIntroduction'
    ]),
    ...mapMutations('system', {
      setThesisInfo: 'setThesisInfo' // 将 `this.add()` 映射为 `this.$store.commit('increment')`
    }),
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const thesisInfo = Object.assign({}, this.thesisInfo, this.form)

          this.setThesisInfo(thesisInfo)

          this.$emit('onCurrentChange', this.current + 1)
        } else {
          return false
        }
      })
    },
    // 上传前
    handleBeforeUpload(file, maxSize) {
      if (file.size > maxSize) {
        this.$message.error('超过上传大小限制')

        return false
      }

      return true
    },
    // 上传成功
    handleUploadSuccess(response, fieldName) {
      const { code, data, msg } = response

      if (code === 200) {
        this.form[fieldName] = decodeURIComponent(data)
      } else if (code === 401) {
        this.$router.push({
          path: '/login'
        })
      } else {
        this.$message.error(msg)
      }
    }
  },
  async created() {
    try {
      await this.fetchIntroduction({
        id: this.conferencesInfo.id
      })
    } catch (e) {

    }
  },
  watch: {
    thesisInfo: {
      handler(value) {
        this.form = Object.assign({}, this.form, value)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
</style>
