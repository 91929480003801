import request from '@/utils/request'

// 摘要投稿状态
export function getSystemThesisDateStatus(params) {
  return request({
    url: '/system/thesis/dateStat',
    // url: 'https://mock.apifox.com/m1/4412676-0-default/system/thesis/dateStat',
    method: 'GET',
    params
  })
}

// 投稿列表
export function getSystemThesisThesisList(params) {
  return request({
    url: '/system/thesis/thesisList',
    // url: 'https://mock.apifox.com/m1/4412676-0-default/system/thesis/thesisList',
    method: 'GET',
    params
  })
}

// 上传或修改摘要
export function postSystemThesisUpsertAbs(data) {
  return request({
    url: '/system/thesis/upsertAbs',
    method: 'POST',
    data
  })
}

// 论文详情
export function getSystemThesisThesisInfo(params) {
  return request({
    url: '/system/thesis/thesisInfo',
    method: 'GET',
    params
  })
}

// 编辑部论文详情
export function getSystemEditorialThesisInfo(params) {
  return request({
    url: '/system/editorial/thesisInfo',
    method: 'GET',
    params
  })
}

// 上传或修改正文
export function postSystemThesisUpsertBody(data) {
  return request({
    url: '/system/thesis/upsertBody',
    method: 'POST',
    data
  })
}

// 下载文件
export function getSystemThesisDownloadFile(params) {
  return request({
    url: '/system/thesis/downloadFile',
    method: 'GET',
    params
  })
}

// 修改密码
export function putSystemUserProfileUpdatePwd(data) {
  return request({
    url: '/portal/puser/updatePwd',
    method: 'POST',
    data
  })
}

// 论文列表
export function getSystemEditorialThesisList(params) {
  return request({
    // url: 'https://mock.apifox.com/m1/4412676-0-default/system/editorial/thesisList',
    url: '/system/editorial/thesisList',
    method: 'GET',
    params
  })
}

// 获取参会信息
export function getSystemAttendInfo(params) {
  return request({
    url: '/system/attend/info',
    // url: 'https://mock.apifox.cn/m1/4412676-4057602-default/system/attend/info',
    method: 'GET',
    params
  })
}

// 补充信息
export function postSystemAttendSuppInfo(data) {
  return request({
    url: '/system/attend/supp',
    // url: 'https://mock.apifox.cn/m1/4412676-4057602-default/system/attend/supp',
    method: 'POST',
    data
  })
}

// 补充信息
export function getSystemEditorialThesisFiles(params) {
  return request({
    url: '/system/editorial/thesisFiles',
    // url: 'https://mock.apifox.cn/m1/4412676-4057602-defaultsystem/editorial/thesisFiles',
    method: 'GET',
    params
  })
}

// 获取字典
export function getSystemDictDataType(name) {
  return request({
    url: `/system/dict/data/type/${name}`,
    // url: `https://mock.apipark.cn/m1/4412676-4057602-default/system/dict/data/type/${name}`,
    method: 'GET'
  })
}