<template>
	<div class="login">
		<div class="login-container">
			<div class="login-form">
				<div class="login-form__head">
					<h3 class="login-form__title">{{ name }}</h3>
				</div>
				<div class="login-form__body">
					<template v-if="state === 'verify'">
						<el-result icon="info" title="验证激活码" subTitle="正在验证，请稍后..."></el-result>
					</template>

					<template v-if="state === 'complete'">
						<el-result icon="success" title="邮件已发送"
							subTitle="已向您的邮箱发送了一封验证邮件，请在10分钟内前往邮箱点击链接激活，超时未激活请重新注册。">
							<template slot="extra">
								<el-button type="primary" size="medium"
									@click="() => $router.replace('/login')">好的</el-button>
							</template>
						</el-result>
					</template>

					<template v-if="state === 'success'">
						<el-result icon="success" title="激活成功" subTitle="邮箱验证激活已通过，可直接登录操作。">
							<template slot="extra">
								<el-button type="primary" size="medium"
									@click="() => $router.replace('/login')">登录</el-button>
							</template>
						</el-result>
					</template>

					<template v-if="state === 'failure'">
						<el-result icon="error" title="激活失败" subTitle="邮箱验证激活失败，请重新注册">
							<template slot="extra">
								<el-button type="primary" size="medium"
									@click="() => $router.replace('/register')">注册</el-button>
							</template>
						</el-result>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Cookies from 'js-cookie'
import { getVerifyRegister } from '@/services/puser'
import AuthCodeButton from '@/components/AuthCodeButton'

export default {
	name: 'RegisterResultView',
	components: {
		AuthCodeButton
	},
	data() {
		return {
		}
	},
	computed: {
		state() {
			return {
				'registerComplete': 'complete',
				'registerSuccess': 'success',
				'registerFailure': 'failure',
				'registerVerify': 'verify'
			}[this.$route.name]
		},
		name() {
			return {
				'complete': '注册完成',
				'success': '激活成功',
				'failure': '激活失败',
				'verify': '邮箱验证'
			}[this.state]
		}
	},
	async created() {
		Cookies.remove('Authorization')
	},
	methods: {
		async handleVertify(uuid) {
			try {
				const validate = await getVerifyRegister({
					uuid
				})

				if (validate) {
					this.$router.replace('/register/success', () => {})
				} else {
					this.$router.replace('/register/failure', () => {})
				}
			} catch (e) {
				console.error(e)
			}
		}
	},
	watch: {
		'$route.query.uuid': {
			handler(value) {
				if (value) {
					this.handleVertify(value)
				}
			},
			deep: true,
			immediate: true
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
@import '@/styles/common';

img {
	width: 100%;
	height: 100%;
}

.login {
	background: #000 url('@/assets/website/2024/assets/images/user/moon-mask.jpg') no-repeat center top;
	background-size: auto 100%;
	min-height: 800px;
	overflow: hidden;

	&-container {
		width: $--cms-page-content-width;
		margin: 0 auto;
	}

	&-form {
		width: 500px;
		margin: 100px auto;
		background: #FFF;
		box-shadow: 0px 1px 4px 2px #DCDCDC;
		border-radius: 14px;
		overflow: hidden;

		&__head {
			padding: 16px 20px;
			background-color: #F6F6F8;
		}

		&__title {
			font-size: $--cms-font-size-md;
			text-align: center;
			color: $--cms-color-text-base;
			font-weight: 600;
			line-height: 28px;
		}

		&__body {
			padding: 30px 60px;
		}
	}
}

@media screen and (max-width: 767px) {
	@include login-common;
}
</style>