<template>
    <el-button class="form-item__authcode-button" :type="enabled ? 'primary' : 'info'" :disabled="!enabled"
        @click="handleAuthCodeSend">{{ remainderStr }}</el-button>
</template>

<script>
export default {
    name: 'AuthCodeButton',
    props: {
        disabled: Boolean
    },
    data() {
        return {
            enabled: true,
            timer: null,
            remainder: 60
        }
    },
    computed: {
        remainderStr() {
            return this.timer ? `${this.remainder} 秒重发` : '获取验证码'
        }
    },
    methods: {
        reset() {
            this.enabled = true
            
            clearInterval(this.timer)

            this.timer = null
            this.remainder = 60
        },
        handleAuthCodeSend() {
            if (this.timer) {
                return false
            }

            this.$emit('send')

            this.enabled = false

            this.timer = setInterval(() => {
                if (this.remainder > 0) {
                    this.remainder--;
                } else {
                    clearInterval(this.timer)

                    this.timer = null
                    this.enabled = true
                    this.remainder = 60
                }
            }, 1000);
        }
    },
    watch: {
        disabled: {
            handler(value) {
                this.enabled = !value
            },
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.form-item__authcode {
    .el-button {
        width: 100px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .el-button--primary {
        background-color: $--cms-primary-color;
        color: #FFF;

        &:hover {
        background-color: $--cms-link-hover-color;
        border-color: $--cms-link-hover-color;
        }
    }

    .el-button--info {
        color: #FFF;
        background-color: #c8c9cc;
        border-color: #c8c9cc;
    }
}
</style>
